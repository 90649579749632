import React from "react";
import "./Kwizdom.css";

const Kwizdom = () => {
  return (
    <>
      <main className="kwizdom-box">

        <div className="kwizdom">
          <div>
            <p>KWIZDOM:</p>
            <div>
              <img src="./logo4.png" alt="kwizdom" />
            </div>
          </div>

          <div>
            <p>Taaza TV, in association with Garden Reach Shipbuilders & Engineers Ltd (GRSE) , under the Ministry of Defence, Government of India, is thrilled to announce a Maha Quiz contest called 'KWIZDOM 2023'. This event has been organized to commemorate Azadi ka AmritMahotsav, India's 75th year of independence.</p>

            <p>KWIZDOM aims to bring together Kolkata's top 108 schools and engage 50,000+ students in fostering academic excellence. This competition is only for students of grades 8, 9, and 10.</p>

            <p>The preliminary round of the quiz show will take place within the school premises, conducted in the classrooms or auditorium. The students will be answering multiple-choice questions (MCQs) based on various subjects revolving around India, general knowledge, current affairs, and more. The students will have 30 minutes to answer the questions on an OMR sheet.</p>
            
            <p>After the preliminary round, top three participants, one from each class, will represent the school as a team in the subsequent rounds. The grand finale will be a live event, taking place on the 5th and 6th of August 2023 at the National Library, Kolkata with a studio audience and media coverage.</p>

            <p>The participation in this quiz competition provides an excellent opportunity for students to showcase their knowledge about their country and compete with talented individuals from other schools. It will foster a spirit of healthy competition and teamwork among students. Furthermore, this event acknowledge the importance of this event for schools as it promotes a sense of national pride through our partnership with GRSE under the Ministry of Defence.</p>
            
            <p>For any queries or participation request, reach us via email at events@taazatv.com.</p>

            <p>This event is presented by Taaza TV and GRSE. Powered by WoW Momo and Emami Healthy and Tasty ADVANS SOYA CHUNKS. Platinum sponsors are: Natural Group, WIN Pens and SURITE Wellness.</p>
          </div>

          <div>
            <img src="/logo1.png" alt="logo1" />
          </div>

          <div>
            <div className="logo3"><img src="/logo3.png" alt="" /></div>
            <div><img src="/sponsors2.png" alt="" /></div>
          </div>

          <div className="social-links-kz">
            <div>
              <img src="/wa.png" alt="" />
            </div>
            <div>
              <img src="/insta.png" alt="" />
            </div>
            <div>
              <img src="/fb.png" alt="" />
            </div>
            <div>
              <img src="/yt.png" alt="" />
            </div>
          </div>
        </div>

      </main>
    </>
  );
};

export default Kwizdom;
