import React, { useContext, useRef } from "react";
import "./Certificate.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { MyContext } from "../../store";
import { Link } from "react-router-dom";
import { FacebookShareButton, FacebookIcon } from 'react-share';


const Certificate = () => {
  const { studentData } = useContext(MyContext);
  console.log(studentData);

  const pdfRef = useRef();

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 0; // Updated value to remove space at the top
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("certificate.pdf");
    });
  };

  const shareUrl = 'https://images.immediate.co.uk/production/volatile/sites/3/2023/03/goku-dragon-ball-guru-824x490-11b2006.jpg'; // URL to share

  //Another way
  // const handleClick = () => {
  //   const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
  //   window.open(facebookUrl, '_blank');
  // };
  return (
    <>
      {studentData && studentData.StudentName ? <main className="certify-box">
        <div className="certify">
          <h1>Welcome Student</h1>

          {studentData ?
            <div className="studentdata">
              <div>
                <p>Student Name: &nbsp;{studentData.StudentName}</p>
                <p>School Name: &nbsp;{studentData.SchoolName}</p>
                <p>Student Grade: &nbsp;{studentData.Grade}</p>
              </div>

              <div>
                <img src="./grade.png" alt="grades" />
              </div>
            </div>
            : <p>NO Data</p>}

          <div>
            <Link to={`/request/${studentData._id}`} style={{ textDecoration: "none", color: "black" }}><button>Change Request</button></Link>
            <button onClick={downloadPDF}>Download Result</button>
          </div>

          <div className="certify-image" ref={pdfRef}>
            <img src="/certificate.png" alt="" />
            <h1>{studentData && studentData.StudentName ? studentData.StudentName : ""}</h1>
            <h1>{studentData && studentData.SchoolName ? studentData.SchoolName : ""}</h1>
          </div>

          <div className="grade">
            <img src="./grade.png" alt="grades" />
          </div>

          {/* <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={32} round />
          </FacebookShareButton> */}


          {/* Another way */}
          {/* <button onClick={handleClick}>
            Share on Facebook
          </button> */}

          <div className="social-visible">
            <div className="social-links-certify">
              <Link to="https://chat.whatsapp.com/BdUJ3oChxtd9CHXxdxkJg9">
                <img src="/wa.png" alt="" />
              </Link>
              <Link to="https://www.instagram.com/taazatvchannel/?hl=en">
                <img src="/insta.png" alt="" />
              </Link>
              <Link to="https://www.facebook.com/events/278766334837530/?acontext=%7B%22event_action_history%22%3A[]%7D">
                <img src="/fb.png" alt="" />
              </Link>
              <Link to="https://www.youtube.com/user/taazatv">
                <img src="/yt.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </main> : <p className="wrong-page">
        <img src="./errorpage3.png" alt="Error Not Found" />
        <button><Link to="/missingrecord" style={{ color: "#000", textDecoration: "none", fontWeight: "500", fontSize: "1.8rem" }}>Find Your Result</Link></button>
      </p>}


    </>
  );
};

export default Certificate;


