import React, { useState, useEffect } from 'react'
import "./EditStudent.css"
import DataTable from "react-data-table-component";
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner'

const Action = ({ id, requestedName, isApproved, Refid }) => {

  const getRequestChange = async (id) => {
    await axios.put(`https://kwizdombackendinit.onrender.com/admin/${id}/${Refid}/${requestedName}`).then()
  }

  const approve = async (id) => {
    const confirm = window.confirm("Are you Sure ?")

    if (confirm) {
      await getRequestChange(id)
      window.location.reload();
    }
  }

  return (
    <>
      <div>
        {isApproved === true ? <button style={{ background: "yellow" }} className='edit-btn1'>Approved</button> : <button onClick={() => approve(id)} className='edit-btn2'>Approve</button>}
      </div>
    </>
  )
};


const columns = [
  {
    name: 'School Name',
    selector: row => row.SchoolName,
    sortable: true,
  },
  {
    name: 'Student Name',
    selector: row => row.StudentName,
    sortable: true,
  },
  {
    name: 'Request StudentName',
    selector: row => row.requestedName,
    sortable: true
  },
  {
    name: 'Phone Number',
    selector: row => row.PhoneNumber,
  },
  {
    name: 'Action',
    cell: row => <Action id={row._id} requestedName={row.requestedName} Refid={row.Refid} isApproved={row.isApproved} />,
  }
];

const EditStudent = () => {

  const storedEmail = localStorage.getItem("email");
  const storedPassword = localStorage.getItem("password");

  console.log(storedEmail)

  const [editStudentList, setEditStudentList] = useState([])
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const geteditStudentList = async () => {
      const { data } = await axios.get(`https://kwizdombackendinit.onrender.com/api/geteditStudentList`)
      setEditStudentList(data)
      setIsLoading(false)
    }
    geteditStudentList()
  }, [setEditStudentList])

  console.log(editStudentList)

  const [filter, setFilter] = useState("");

  const EditStudentListdata = editStudentList.filter((item) => {
    const RefId = item.Refid;
    const SchoolName = item.SchoolName.toLowerCase();
    const StudentName = item.StudentName.toLowerCase();

    return (
      RefId.includes(filter.toLowerCase()) ||
      SchoolName.includes(filter.toLowerCase()) ||
      StudentName.includes(filter.toLowerCase())
    );
  });

  return (
    <>
      {
        storedEmail && storedPassword ? <main className='editstudent-box'>
          <div className='editstudent'>
            {/* <p>EDIT STUDENT LIST</p> */}
            <div>
              <input
                type="text"
                placeholder="Search"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>

            <DataTable
              columns={columns}
              data={EditStudentListdata}
              pagination
              progressPending={isLoading}
              progressComponent={<ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#23407C', '#23407C', '#23407C', '#23407C', '#23407C']}
              />}
            />
          </div>
        </main> : <p style={{ marginTop: "5rem", marginLeft: "3rem", fontSize: "3rem", fontWeight: "500", color: "#EC952B" }}>Please Sign In</p>
      }

    </>
  )
}

export default EditStudent
