import React, { useState, useEffect } from 'react'
import "./SchoolList.css"
import DataTable from "react-data-table-component";
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner'

const columns = [
    {
        name: 'School Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Results',
        selector: row => row.results,
        sortable: true,
    }
];


const SchoolList = () => {

    const [schoollist, setSchoollist] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getSchoolList = async () => {
            const { data } = await axios.get(`https://kwizdombackendinit.onrender.com/api/getschoolList`)
            setSchoollist(data)
            setIsLoading(false)
        }
        getSchoolList()
    }, [setSchoollist])

    const [filter, setFilter] = useState("");




    const SchoolListdata = schoollist.filter((item) => {
        const schoolListName = item.name.toLowerCase().replace(/\s/g, '');
        const Result = item.results.toLowerCase();

        console.log(schoolListName)

        return (
            schoolListName.includes(filter.toLowerCase()) ||
            Result.includes(filter.toLowerCase())
        );
    });
    return (
        <>
            <main className='schoollist-box'>
                <div className="schoollist">
                    {/* <p>SCHOOL LIST</p> */}
                    <div>
                        <input
                            type="text"
                            placeholder="Search"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                    </div>

                    <DataTable
                        columns={columns}
                        data={SchoolListdata}
                        pagination
                        progressPending={isLoading}
                        progressComponent={<ColorRing
                            visible={true}
                            height="50"
                            width="50"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#23407C', '#23407C', '#23407C', '#23407C', '#23407C']}
                        />}
                    />
                </div>
            </main>
        </>
    )
}

export default SchoolList
