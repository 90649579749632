import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import "./Login.css"
import { MyContext } from '../../store';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader"

const Login = () => {
  const { admin, setAdmin } = useContext(MyContext);
  // console.log(admin)

  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault()
    setIsLoading(true);

    if (!email && !password) {
      toast.error("Please Enter All the Fields", {
        icon: false
      });
      setIsLoading(false)
    } else if (!email) {
      toast.error("Please Enter Your Email", {
        icon: false
      });
      setIsLoading(false)
    } else if (!password) {
      toast.error("Please Enter Your Password", {
        icon: false
      });
      setIsLoading(false)
    } else {
      try {
        const { data } = await axios.post("https://kwizdombackendinit.onrender.com/admin/signin", {
          email,
          password,
        });
        setAdmin(data);
        console.log(data)
        setIsLoading(false)

        if (data.message) {
          toast.error(data.message, {
            icon: false
          });
        }
        if (data[0].email === email && data[0].password === password) {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password);

          // Set a timer to remove email and password from localStorage after 3 seconds (3000 milliseconds)
          setTimeout(() => {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
          }, 1800000);
          navigate("/dashboard")
        }
      } catch (error) { }

    }
  }

  // if (admin) {
  //   // navigate("/dashboard")
  //   // localStorage.setItem('admin',JSON.stringify(data[0]))
  // } else {

  // }
  return (
    <>
      <main className='login-box'>
        <div>
          <img src="/logo1.png" alt="" />
        </div>


        <div className='login'>
          <h1>Sign In</h1>
          <div>
            <label>Email</label>
            <input
              type="email"
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
            />
          </div>

          <div>
            <label htmlFor="">Password</label>
            <input
              type="password"
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button onClick={submitHandler}>{isLoading ? (
            <Loader height={"30"} width={"30"} />
          ) : (
            "Sign In"
          )}</button>
        </div>

      </main>
      <ToastContainer className="toast" />
    </>
  )
}

export default Login