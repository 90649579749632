import React from "react";
import "./Coming.css";
import { Link } from "react-router-dom";

const Coming = () => {
  return (
    <>
      <main className="main-box2">
        <div className="main2">
          <div className="upper-div">
            <div className="left-cm">
              <div>
                <p>
                  Taaza TV & GRSE (A Govt. of India Undertaking Min. of Defence)
                </p>
                <p>presents</p>
              </div>

              <p>Biggest Quiz Contest on Glorious India!</p>

              <p>
                with 50,000 students participating of classes 8,9,10 from over
                100 schools of Kolkata and around as our endeavour to promote
                the idea of India among the young budding future. The quiz
                contest aims to ignite curiosity and foster a deep connection
                with the idea of India in its 75th year of Independence - Azadi
                ka Amrit Mahotsav.
              </p>
            </div>

            <div className="right-cm">
              <div>
                <Link to="/search"><img src="/results2.png" alt="" /></Link>
                <Link to="/schoollist" className="right-cm-list">School Wise Result List</Link>
              </div>
            </div>
          </div>


          <div className="down-div">
            <div>
              <img src="/logo1.png" alt="" />
            </div>

            <div>
              <div>
                <img src="/logo3.png" alt="" />
              </div>
              <div>
                <img src="/herologo2.png" alt="logo2" />
              </div>
            </div>
          </div>

          <div className="sponsors2">
            <img src="/sponsors2.png" alt="" />
          </div>
        </div>
      </main>

    </>
  );
};

export default Coming;
