import React from 'react'
import "./Dashboard.css"
import { Link } from 'react-router-dom'

const Dashboard = () => {

  const storedEmail = localStorage.getItem("email");
  const storedPassword = localStorage.getItem("password");

  return (
    <>
      {
        storedEmail && storedPassword ? <main className='dashboard-box'>
          <div className="dashboard">
            <div className='list'>
              <Link to="/editstudentlist" className='linklist'>Edit Student List</Link>
              <Link to="/missingrecordlist" className='linklist'>Missing Record List</Link>
            </div>
          </div>
        </main > : <p style={{ marginTop: "5rem", marginLeft: "3rem", fontSize: "3rem", fontWeight: "500", color: "#EC952B" }}>Please Sign In</p>
      }
    </>
  )
}

export default Dashboard
