import React, { useState, useEffect } from "react";
import "./ChangeRequest.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Combobox } from "@headlessui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangeRequest = () => {
  const { id } = useParams();

  const [newData, setNewData] = useState({});

  const [requestedName, setRequestedName] = useState("");
  const [requestedSchoolName, setRequestedSchoolName] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState(0);
  const [query, setQuery] = useState("");
  const [schoolNames, setSchoolNames] = useState([]);
  const [error, setError] = useState("")


  useEffect(() => {
    const getStudent = async () => {
      const { data } = await axios.post(`https://kwizdombackendinit.onrender.com/api/${id}/edit`)
      console.log(data)
      setRequestedName(data.StudentName)
      setRequestedSchoolName(data.SchoolName)
      setPhoneNumber(data.PhoneNumber)
    }
    getStudent()
  }, [id])

  //SchoolList Api
  useEffect(() => {
    try {
      const getAllSchoolNames = async () => {
        await axios.get(
          "https://kwizdombackendinit.onrender.com/api/schoolList",
        ).then(({ data }) => setSchoolNames(data))
          .catch(err => toast.error("Network Error", {
            icon: false,
          }));
        ;
      }

      getAllSchoolNames()
    } catch (error) {
      setError("No Data")
    }

  }, [])


  const filteredSchool =
    query === ""
      ? schoolNames
      : schoolNames.filter((schoolName) => {
        return schoolName
          .toLocaleLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLocaleLowerCase().replace(/\s+/g, ""));
      });

  console.log(newData);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!requestedName && !requestedSchoolName) {
      toast.error("Please Enter All the Fields", {
        icon: false,
      });
    } else if (!requestedName) {
      toast.error("Please Enter the Student Name", {
        icon: false,
      });
    } else if (!requestedSchoolName) {
      toast.error("Please Enter the School Name", {
        icon: false,
      });
    } else {
      const { data } = await axios.put(`https://kwizdombackendinit.onrender.com/api/${id}/new`, {
        requestedName,
        requestedSchoolName,
        PhoneNumber
      });
      setNewData(data);

      setRequestedName("");
      setRequestedSchoolName("");
      toast.success("Request has been Sent", {
        icon: false,
      });
    }
  };
  return (
    <>
      <main className="request_box">
        <div className="request">

          <div className="request-left">
            <div>
              <img src="/logo1.png" alt="" />
            </div>


            <form>
              <h1>Edit</h1>
              <div>
                <label>Student Name</label>
                <input
                  type="text"
                  placeholder="Enter Your Name"
                  value={requestedName}
                  onChange={(e) => setRequestedName(e.target.value)}
                />
              </div>

              <div>
                <label>School Name</label>
                <Combobox
                  value={requestedSchoolName}
                  onChange={setRequestedSchoolName}
                >
                  <Combobox.Input
                    onChange={(event) => setQuery(event.target.value)}
                    className="combo-input"
                    autoComplete="off"
                    placeholder="School Names"
                  />

                  <Combobox.Options className="combo2">
                    {filteredSchool.length > 0 ? (
                      filteredSchool.slice(0, 8).map((school) => (
                        <Combobox.Option
                          key={school}
                          value={school}
                          className="option"
                        >
                          {({ active, selected }) => (
                            <div className={`${active ? "bg-green" : "bg-gray"}`}>
                              {school}
                            </div>
                          )}
                        </Combobox.Option>
                      ))
                    ) : (
                      <div style={{ fontSize: "var(--tertiary-font)" }}>
                        No SchoolName present
                      </div>
                    )}
                  </Combobox.Options>
                </Combobox>
              </div>

              <button onClick={submitHandler}>Send Request</button>
            </form>
          </div>

          <div className="request-right">
            <img src="/edit.png" alt="" />
          </div>

        </div>

        <div className="social-visible">
          <div className="social-links-cg">
            <div>
              <img src="/wa.png" alt="" />
            </div>
            <div>
              <img src="/insta.png" alt="" />
            </div>
            <div>
              <img src="/fb.png" alt="" />
            </div>
            <div>
              <img src="/yt.png" alt="" />
            </div>
          </div>
        </div>
      </main>
      <ToastContainer className="toast" />
    </>
  );
};

export default ChangeRequest;
