import React, { useContext, useState, useEffect } from "react";
import "./Forgot.css";
import axios from "axios";
import { MyContext } from "../../store";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
import { Combobox } from "@headlessui/react";

const Forgot = () => {
  const { studentData, setStudentData } = useContext(MyContext);
  console.log(studentData);

  const [newDOB, setNewDOB] = useState("");
  const [StudentName, setStudentName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [schoolNames, setSchoolNames] = useState([]);
  const [SchoolName, setSchoolName] = useState("");
  const [query, setQuery] = useState("");


  useEffect(() => {
    try {
      const getAllSchoolNames = async () => {
        await axios.get(
          "https://kwizdombackendinit.onrender.com/api/schoolList",
        ).then(({ data }) => setSchoolNames(data))
          .catch(err => toast.error("Network Error", {
            icon: false,
          }));
        ;
      }

      getAllSchoolNames()
    } catch (error) {
      console.log(error)
    }

  }, [])

  const filteredSchool =

    query === ""
      ? schoolNames
      : schoolNames.length > 0 ? schoolNames.filter((schoolName) => {

        return schoolName
          .toLocaleLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLocaleLowerCase().replace(/\s+/g, ""));
      }

      ) : [];

  const convertDateFormat = (date) => {
    const dateParts = date.split("-"); // Splitting the date string by dashes
    const year = dateParts[0]; // Extracting the last two digits of the year
    const month = dateParts[1];
    const day = dateParts[2];
    return `${day}-${month}-${year}`;
  };

  const DOB = convertDateFormat(newDOB);

  const navigate = useNavigate();

  const SearchHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!StudentName && !newDOB) {
      toast.error("Please Enter All the Fields", {
        icon: false
      });
      setIsLoading(false)
    } else if (!StudentName) {
      toast.error("Please Enter Student Name", {
        icon: false
      });
      setIsLoading(false)
    } else if (!newDOB) {
      toast.error("Please Enter Date of Birth", {
        icon: false
      });
      setIsLoading(false)
    } else {

      try {
        const { data } = await axios.post(
          "https://kwizdombackendinit.onrender.com/api/forgot/new",
          {
            StudentName,
            SchoolName,
            DOB,
          }
        );
        setStudentData(data);

        navigate("/certificate");
        setIsLoading(false); // Hide loader
      } catch (error) {
        console.log(error)
      }
    }
  };

  return (
    <>
      <main className="forgot-box">
        <div className="forgot">

          <div className="forgot-left">
            <div>
              <img src="/logo1.png" alt="" />
            </div>

            <form>
              <h1>Search</h1>

              <div>
                <label>Student Name</label>
                <input
                  type="text"
                  placeholder="Enter Your Name"
                  onChange={(e) => setStudentName(e.target.value)}
                />
              </div>

              <Combobox value={SchoolName} onChange={setSchoolName} >
                <label htmlFor="" className="combo-label2">School Name</label>
                <Combobox.Input
                  onChange={(event) => setQuery(event.target.value)}
                  autoComplete="off"
                  className="combo-input2"
                  placeholder="School Name"
                />

                <Combobox.Options className="combo2">
                  {filteredSchool.length > 0 ? (
                    filteredSchool.slice(0, 8).map((school) => (
                      <Combobox.Option
                        key={school}
                        value={school}
                      // className="option"
                      >
                        {({ active, selected }) => (
                          <div className={`${active ? "bg-green" : "bg-gray"}`}>
                            {school}
                          </div>
                        )}
                      </Combobox.Option>
                    ))
                  ) : (
                    <div style={{ fontSize: "var(--tertiary-font)" }}>
                      No SchoolName present
                    </div>
                  )}
                </Combobox.Options>
              </Combobox>

              <div>
                <label>Date of Birth</label>
                <input
                  type="date"
                  placeholder="Date of Birth"
                  onChange={(e) => setNewDOB(e.target.value)}
                />
              </div>

              <button onClick={SearchHandler}>
                {" "}
                {isLoading ? (
                  <Loader height={"30"} width={"30"} />
                ) : (
                  "Search"
                )}
              </button>
            </form>
          </div>

          <div className="forgot-right">
            <img src="/forgot.png" alt="" />
          </div>
        </div>

        <div className="social-visible">
          <div className="social-links-fg">
            <div>
              <img src="/wa.png" alt="" />
            </div>
            <div>
              <img src="/insta.png" alt="" />
            </div>
            <div>
              <img src="/fb.png" alt="" />
            </div>
            <div>
              <img src="/yt.png" alt="" />
            </div>
          </div>
        </div>
      </main>

      {/* For Error Part  */}
      <ToastContainer className="toast" />
    </>
  );
};

export default Forgot;
