import React from 'react'
import {ColorRing} from 'react-loader-spinner'

const Loader = ({height,width}) => {
  return (
    <>
    <ColorRing
    height={height}
    width={width}
    colors={["white","white","white","white","white"]}/>
    </>
  )
}

export default Loader