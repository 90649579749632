import React, { useContext, useEffect, useState } from "react";
import "./SearchResult.css";
import { Combobox } from "@headlessui/react";
import { useNavigate, Link } from "react-router-dom";
import { MyContext } from "../../store.js";
import axios from "axios";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faSquareInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";


const SearchResult = () => {
  const { studentData, setStudentData } = useContext(MyContext);
  console.log(studentData);

  const [PhoneNumber, setPhoneNumber] = useState("");
  const [SchoolName, setSchoolName] = useState("");
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [schoolNames, setSchoolNames] = useState([]);
  const [error, setError] = useState("")

  useEffect(() => {
    try {
      const getAllSchoolNames = async () => {
        await axios.get(
          "https://kwizdombackendinit.onrender.com/api/schoolList",
        ).then(({ data }) => setSchoolNames(data))
          .catch(err => toast.error("Network Error", {
            icon: false,
          }));
        ;
      }

      getAllSchoolNames()
    } catch (error) {
      setError("No Data")
    }

  }, [])

  const filteredSchool =

    query === ""
      ? schoolNames
      : schoolNames.length > 0 ? schoolNames.filter((schoolName) => {

        return schoolName
          .toLocaleLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLocaleLowerCase().replace(/\s+/g, ""));
      }

      ) : [];

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader

    if (!SchoolName && !PhoneNumber) {
      toast.error("Please Enter All the Fields", {
        icon: false,
      });
      setIsLoading(false);
    } else if (!SchoolName) {
      toast.error("Please Enter SchoolName", {
        icon: false,
      });
      setIsLoading(false);
    } else if (!PhoneNumber) {
      toast.error("Please Enter PhoneNumber", {
        icon: false,
      });
      setIsLoading(false);
    } else if (PhoneNumber && PhoneNumber.length < 10) {
      toast.error("Phone Number should be atleast 10 digits", {
        icon: false,
      });
      setIsLoading(false);
    }
    else if (PhoneNumber && PhoneNumber.length > 10) {
      toast.error("Phone Number cannot exceed 10 digits", {
        icon: false,
      });
      setIsLoading(false);
    }
    else {
      try {
        const { data } = await axios.post(
          "https://kwizdombackendinit.onrender.com/api/search",
          {
            SchoolName,
            PhoneNumber,
          }
        );
        setStudentData(data);

        navigate("/certificate");
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <>
      <main className="search-result-container">
        <div className="search-result">
          <div className="left">
            <div className="hero-logo">
              <img src="/herologo.png" alt="" />
            </div>

            <p>Enter all the necessary info and Get your result.</p>

            <form className="form">

              <Combobox value={SchoolName} onChange={setSchoolName}>
                <Combobox.Input
                  onChange={(event) => setQuery(event.target.value)}
                  className="combo-input"
                  autoComplete="off"
                  placeholder="School Name"
                />

                <Combobox.Options className="combo">
                  {filteredSchool.length > 0 ? (
                    filteredSchool.slice(0, 8).map((school) => (
                      <Combobox.Option
                        key={school}
                        value={school}
                        className="option"
                      >
                        {({ active, selected }) => (
                          <div className={`${active ? "bg-green" : "bg-gray"}`}>
                            {school}
                          </div>
                        )}
                      </Combobox.Option>
                    ))
                  ) : (
                    <div style={{ fontSize: "var(--tertiary-font)" }}>
                      No SchoolName present
                    </div>
                  )}
                </Combobox.Options>
              </Combobox>

              <input
                type="number"
                placeholder="Mobile Number"
                value={PhoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />

              <button onClick={submitHandler}>
                {isLoading ? (
                  <Loader height={"28"} width={"28"} />
                ) : (
                  "Get your Result"
                )}
              </button>

              <Link
                to="/forgot"
                style={{}}
              >
                <p>Forgot Mobile Number ?</p>
              </Link>
            </form>


            <div className="bharat">
              <img src="/herologo2.png" alt="" />
            </div>
          </div>

          <div className="right">
            <div className="hero-img">
              <img src="/hero.png" alt="" />
            </div>
            <div className="lower-div2">
              <img src="/sponsors.png" alt="" />
            </div>
          </div>

          <div className="lower-div">
            <img src="/sponsors4.png" alt="" />
          </div>

          <div className="social-links">
            <div>
              <img src="/wa.png" alt="" />
            </div>
            <div>
              <img src="/insta.png" alt="" />
            </div>
            <div>
              <img src="/fb.png" alt="" />
            </div>
            <div>
              <img src="/yt.png" alt="" />
            </div>
          </div>
        </div>
      </main>
      <div className="bar-line" />
      <div className="bar-line-2">
        <div className="inner-bar">
          <div>
            <p>Follow Us on</p>
            <span>
              <FontAwesomeIcon icon={faFacebook} />
            </span>
            <span>
              <FontAwesomeIcon icon={faTwitter} />
            </span>
            <span>
              <FontAwesomeIcon icon={faSquareInstagram} />
            </span>
            <span>
              <FontAwesomeIcon icon={faYoutube} />
            </span>
            <p>Taaza Tv</p>
            <p>To Watch Taaza Tv live download the mobile App</p>
          </div>

          <div>
            <div>
              <Link to="https://play.google.com/store/apps/details?id=com.maxmobility.taazatv"><img src="/googleplay.webp" alt="playstore" /></Link>
            </div>
            <div>
              <Link to="https://apps.apple.com/in/app/taaza-tv/id6450483538"><img src="/appstore.png" alt="appstore" /></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bar-line-3">
        <p>
          Taaza TV is available on Siti Cable (171), Hathway (214), GTPL (213)
          also on JIO TV / Daily Hunt.
        </p>
      </div>

      {/* <div className="lower-div2">
        <img src="/sponsors.png" alt="" />
      </div> */}

      {/* For Error Part  */}
      <ToastContainer className="toast" />
      {error && <p>{error}</p>}
    </>
  );
};

export default SearchResult;
