import React, { useState, useEffect } from 'react'
import "./MissingRecordList.css"
import DataTable from "react-data-table-component";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from 'react-loader-spinner';

const CreateRecord = ({ SchoolName, StudentName, fullDOB, PhoneNumber, isCreated }) => {
    const [create2, setCreate] = useState(false)
    const create = (SchoolName, StudentName, fullDOB, PhoneNumber, isCreated) => {



        const originalDate = fullDOB;
        const dateParts = originalDate.split("-");

        const year = dateParts[0].substring(2);
        const month = dateParts[1];
        const day = dateParts[2];

        const DOB = `${day}${month}${year}`

        const createrecordfunc = async () => {
            const { data } = await axios.post("https://kwizdombackendinit.onrender.com/api/createrecord", {
                SchoolName,
                StudentName,
                DOB,
                PhoneNumber
            })
            console.log(data)
        }
        const confirm = window.confirm("Are you Sure ?")
        if (confirm) {
            createrecordfunc();
            toast.success("Record has been successfully Created...", {
                icon: false,
            });
            setCreate(true)
        }
    };

    return (
        <>
            {
                isCreated === true || create2 === true ? <button className='createbtn2'>created</button> : <button onClick={() => create(SchoolName, StudentName, fullDOB, PhoneNumber, isCreated)} className='createbtn'>Create</button>
            }
        </>
    );
};

const columns = [
    {
        name: 'School Name',
        selector: row => row.SchoolName,
        sortable: true,
    },
    {
        name: 'Student Name',
        selector: row => row.StudentName,
        sortable: true,
    },
    {
        name: 'Date of Birth',
        selector: row => row.DOB,
        sortable: true,
    },
    {
        name: 'Phone Number',
        selector: row => row.PhoneNumber,
        sortable: true,
    },
    {
        name: 'Remarks',
        selector: row => row.Remarks,
        sortable: true,
    },
    {
        name: 'Action',
        cell: (row) => <CreateRecord SchoolName={row.SchoolName} StudentName={row.StudentName} fullDOB={row.DOB} PhoneNumber={row.PhoneNumber} isCreated={row.isCreated} />
    }
];

const MissingRecordList = () => {

    const [missingRecordlist, setMissingRecordlist] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");

    useEffect(() => {
        const getmissingRecordlist = async () => {
            const { data } = await axios.get(`https://kwizdombackendinit.onrender.com/api/getMissingrecordList`)
            setMissingRecordlist(data)
            setIsLoading(false)
        }
        getmissingRecordlist()
    }, [setMissingRecordlist])

    console.log(missingRecordlist)

    const [filter, setFilter] = useState("");

    const MissingRecordListdata = missingRecordlist.filter((item) => {
        // const SchoolName = item.SchoolName.toLowerCase().replace(/\s/g, '');
        const SchoolName = item.SchoolName;
        const StudentName = item.StudentName;
        const DOB = item.DOB;
        const Remarks = item.Remarks;

        return (
            SchoolName.includes(filter.toLowerCase()) ||
            StudentName.includes(filter.toLowerCase()) ||
            DOB.includes(filter.toLowerCase()) ||
            Remarks.includes(filter.toLowerCase())
        );
    });
    return (
        <>
            {
                storedEmail && storedPassword ? <main className='missingrecord-box'>
                    <div className='missingrecord'>
                        {/* <p>MISSING RECORDS LIST</p> */}
                        <div>
                            <input
                                type="text"
                                placeholder="Search"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </div>

                        <DataTable
                            columns={columns}
                            data={MissingRecordListdata}
                            pagination

                            progressPending={isLoading}
                            progressComponent={<ColorRing
                                visible={true}
                                height="50"
                                width="50"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#23407C', '#23407C', '#23407C', '#23407C', '#23407C']}
                            />}
                        />
                    </div>
                    <ToastContainer className="toast" />
                </main> : <p style={{ marginTop: "5rem", marginLeft: "3rem", fontSize: "3rem", fontWeight: "500", color: "#EC952B" }}>Please Sign In</p>
            }
        </>
    )
}

export default MissingRecordList