import React from "react";
import "./GRSE.css";

const GRSE = () => {
  return (
    <>
      <main className="grse-box">
        <div className="grse">
          <p>
            {/* ABOUT <span>&nbsp;GRSE</span> */}
          </p>


          <div className="grse-logo">
          <div>
            <img src="/grse.png" alt="grse" />
          </div>

          <div>
            <img src="/grse2.png" alt="warship" />
          </div>
          </div>
          

          <div>
            <p>Genesis</p>

            <p>The genesis of Garden Reach Shipbuilders & Engineers Ltd (GRSE) dates back to 1884 when it started its journey as a small workshop to repair vessels of River Steam Navigation Company. In 1934, the Company was registered under the Indian Companies Act, 1913 and started its new journey as Garden Reach Workshops (GRW) Limited. The Shipyard played an active role during the 2nd World Warwherein over 4000 vessels were repaired here. The Company was taken over by the Government of India in 1960. GRSE has the distinction of becoming the first shipyard of independent India to build a warship for Indian Navy, the Seaward Defence Boat (SDB) INS Ajay way back in 1961. GRSE also built the first ever Indian Export Warship “CGS Barracuda”. The shipyard was conferred the status of a Miniratna Category I Company in 2006. GRSE has built 785+ platforms including 100+ warships for Indian Navy, Indian Coast Guard, and Government of Mauritius & Seychelles Coast Guard - highest warships built & delivered by any Indian shipyard till date.</p>

            <p>The primary role of this ISO 9001, 14001, 18001, 50001 & PCMM Level 2 certified shipyard has always been building warships and other vessels for the Indian Navy and Indian Coast Guard. With its modern shipbuilding infrastructure, state-of-the-art Virtual Reality Lab and a 100+strong design team drawing on over 60 years of shipbuilding expertise, GRSE can certainly lay claim to being the 'Master Builder' for Amphibious & Survey ships, Corvettes and Fast Attack Crafts in India. Apart from Ship Building & Ship Repairs, GRSE has also diversified into engineering business with a product profile of pre-fabricated steel bridges, various deck machinery items and assembly, as well as testing & overhauling of marine diesel engines. GRSE is playing a key role in defence preparedness of India to produce the most modern warships through indigenization for the country aimed at self- reliance. Post modernization and revitalization, the shipyard has the capacity to build 20 ships (08 large & 12 small) concurrently.</p>
          </div>

          <div>
            <p>Recent Achievements</p>
            <ul>
                <li>Only Indian Shipyard to deliver 100 Warships since 1960 (108 Warships delivered till 31 Mar 23)</li>

                <li>Only PSU Shipyard to receive Raksha Mantri’s Award 2022 for Excellence in Defence & Aerospace Sector: Design of Most Silent Ship for IN, for ASW Operations</li>

                <li>Defence Minister's Award of Excellence for in-house Design Effort for OPV built for Government of Mauritius</li>

                <li>Design and R&D Unit of GRSE has been recognized by DSIR, Ministry of Science and Technology, Gol</li>
                <li>A Profit Making and Dividend Paying Company for the past29 years </li>
                <li>1St Defence Shipyard to get listed with Stock Exchanges </li>
                <li>Approximately 90% Indigenous Content on ASW Corvettes & LCUs</li>
                <li>Bagged an Order to build Next Generation Electric Ferry for Government of West Bengal</li>
                <li>Only Shipyard to receive Green Channel Certification by DGQA for supply of Bailey Portable Steel Bridges to Indian Army</li>
                <li>Developed Double Lane Portable Steel Bridge and Portable Assault Bridge for the 1St time in India</li>
                <li>Delivered Export Warship, SCG PS Zoroaster, FPV to Seychelles Coast Guard</li>
                <li>Delivered Export Vessel, MV 'Ma Lisha', an Ocean Going Passenger & Cargo Ferry to Cooperative Republic of Guyana Best Performing Defence Shipyard of India for Four Years in a Row</li>
                <li>Bagged Prestigious Awards for R&D, CSR, Corporate Governance, HR Practices, Communication Outreach, Digital Transformation, Best Organisational Efforts, R&D, Outstanding Initiatives in promoting Medical & Health etc.</li>
            </ul>
          </div>
        </div>
      </main>
    </>
  );
};

export default GRSE;
