import React, { useState, useEffect } from "react";
import "./MissingRecord.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Combobox } from "@headlessui/react";

const MissingRecord = () => {

  const [StudentName, setStudentName] = useState("")
  const [PhoneNumber, setPhoneNumber] = useState(0)
  const [DOB, setDOB] = useState("")
  const [Remarks, setRemarks] = useState("")
  const [schoolNames, setSchoolNames] = useState([]);
  const [SchoolName, setSchoolName] = useState("");
  const [query, setQuery] = useState("");


  useEffect(() => {
    try {
      const getAllSchoolNames = async () => {
        await axios.get(
          "https://kwizdombackendinit.onrender.com/api/schoolList",
        ).then(({ data }) => setSchoolNames(data))
          .catch(err => toast.error("Network Error", {
            icon: false,
          }));
        ;
      }

      getAllSchoolNames()
    } catch (error) {
      console.log(error)
    }

  }, [])

  const filteredSchool =

    query === ""
      ? schoolNames
      : schoolNames.length > 0 ? schoolNames.filter((schoolName) => {

        return schoolName
          .toLocaleLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLocaleLowerCase().replace(/\s+/g, ""));
      }

      ) : [];


  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (!StudentName && !SchoolName && !PhoneNumber && !DOB) {
      toast.error("Please Enter All the Fields", {
        icon: false,
      });
    } else if (!StudentName) {
      toast.error("Enter Your Name", {
        icon: false,
      });
    } else if (!SchoolName) {
      toast.error("Enter Your School Name", {
        icon: false,
      });
    } else if (!PhoneNumber) {
      toast.error("Enter Your Phone Number", {
        icon: false,
      })
    } else if (PhoneNumber && PhoneNumber.length < 10) {
      toast.error("Phone Number should be atleast 10 digits", {
        icon: false,
      });
    }
    else if (PhoneNumber && PhoneNumber.length > 10) {
      toast.error("Phone Number cannot exceed 10 digits", {
        icon: false,
      });
    } else if (!DOB) {
      toast.error("Enter Your DOB", {
        icon: false,
      });
    } else if (!Remarks) {
      toast.error("Enter Your Remarks", {
        icon: false,
      });
    } else {
      try {
        const { data } = await axios.post("https://kwizdombackendinit.onrender.com/api/missingRecord", { StudentName, SchoolName, PhoneNumber, DOB, Remarks })
        toast.success("Record has been Sent", {
          icon: false,
        });
        setStudentName("")
        setSchoolName("")
        setPhoneNumber("")
        setDOB("")
        console.log(data)
      } catch (error) {
        console.error("Axios error:", error);
      }
    }

  };

  return (
    <>
      <main className="record-box">
        <div className="record">

          <div className="record-left">
            <div>
              <img src="/logo1.png" alt="" />
            </div>

            <div>
              <img src="/forgot.png" alt="" />
            </div>
          </div>

          <div className="record-right">
            <form>
              <h1>Find Missing Record</h1>
              <p>Please fill this form in case your result is missing from our record. We will find it for you and update it.</p>

              <div>
                <label>Student Name</label>
                <input
                  type="text"
                  onChange={(e) => setStudentName(e.target.value)}
                  placeholder="Enter Your Name"
                />
              </div>

              <Combobox value={SchoolName} onChange={setSchoolName}>
                <label htmlFor="" className="combo-label2">School Name</label>
                <Combobox.Input
                  onChange={(event) => setQuery(event.target.value)}
                  className="combo-input4"
                  autoComplete="off"
                  placeholder="School Name"
                />

                <Combobox.Options className="combo4">
                  {filteredSchool.length > 0 ? (
                    filteredSchool.slice(0, 8).map((school) => (
                      <Combobox.Option
                        key={school}
                        value={school}
                        className="option"
                      >
                        {({ active, selected }) => (
                          <div className={`${active ? "bg-green" : "bg-gray"}`}>
                            {school}
                          </div>
                        )}
                      </Combobox.Option>
                    ))
                  ) : (
                    <div style={{ fontSize: "var(--tertiary-font)" }}>
                      No SchoolName present
                    </div>
                  )}
                </Combobox.Options>
              </Combobox>

              <div>
                <label>Phone Number</label>
                <input
                  type="number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter Your Phone Number"
                />
              </div>

              <div>
                <label>Date of Birth</label>
                <input
                  type="date"
                  onChange={(e) => setDOB(e.target.value)}
                  placeholder="Date of Birth"
                />
              </div>

              <div>
                <label>Remarks</label>
                <input
                  type="text"
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder="Enter Your Remarks"
                />
              </div>

              <button onClick={SubmitHandler}>

                Send
              </button>
            </form>
          </div>
        </div>

        <div className="social-visible">
          <div className="social-links-fg">
            <div>
              <img src="/wa.png" alt="" />
            </div>
            <div>
              <img src="/insta.png" alt="" />
            </div>
            <div>
              <img src="/fb.png" alt="" />
            </div>
            <div>
              <img src="/yt.png" alt="" />
            </div>
          </div>
        </div>
      </main>

      {/* For Error Part  */}
      <ToastContainer className="toast" />
    </>
  );
};

export default MissingRecord;
