import React,{useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SearchResult from "./components/SearchResult/SearchResult";
import Header from "./components/Header/Header";
import Forgot from "./components/Forgot/Forgot";
import ChangeRequest from "./components/ChangeRequest/ChangeRequest";
import Certificate from "./components/Certificate/Certificate";
import Login from "./components/Login/Login";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import Coming from "./components/Coming/Coming";
import { MyContextProvider } from "./store";
import GRSE from "./components/GRSE/GRSE";
import Kwizdom from "./components/Kwizdom/Kwizdom";
import MissingRecord from "./components/MissingRecord/MissingRecord";
import SchoolList from "./components/SchoolList/SchoolList";
import EditStudent from "./components/EditStudent/EditStudent";
import MissingRecordList from "./components/MissingRecordList/MissingRecordList";

// import ReactGA from 'react-ga4';

// const TRACKING_ID = 'G-WQMB968LC5'

// ReactGA.initialize(TRACKING_ID);

const App = () => {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  return (
    <>
      <MyContextProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Coming />} />
            <Route path="/search" element={<SearchResult />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/request/:id" element={<ChangeRequest />} />
            <Route path="/certificate" element={<Certificate />} />
            <Route path="/grse" element={<GRSE />} />
            <Route path="/kwizdom" element={<Kwizdom />} />
            <Route path="/missingrecord" element={<MissingRecord />} />
            <Route path="/schoollist" element={<SchoolList />} />
            <Route path="/editstudentlist" element={<EditStudent />} />
            <Route path="/missingrecordlist" element={<MissingRecordList/>}/>

            

            <Route path="/signin" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
      </MyContextProvider>
    </>
  );
};

export default App;
