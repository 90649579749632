import React, { useState } from "react";
import "./Header.css";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Header = () => {

  const [mobileMenu, setMobileMenu] = useState(false)

  const mobileMenuHandler = () => {
    setMobileMenu(!mobileMenu)
  }
  return (
    <>
      <main className="head-container">
        <div className="header">
          <Link
            to="/search"
            style={{ textDecoration: "none", color: "var(--color-white)" }}
          >
            <h1>KWiZDoM</h1>
          </Link>

          <div>
            <NavLink to="/" className="link">
              <p>Home</p>
            </NavLink>
            <NavLink to="/search" className="link">
              <p>Results</p>
            </NavLink>
            <NavLink to="/kwizdom" className="link">
              <p>About Kwizdom</p>
            </NavLink>
            <NavLink to="/grse" className="link">
              <p>About GRSE</p>
            </NavLink>
            <NavLink to="https://taazatv.com/" className="link" target="_blank">
              <p>TaazaTv</p>
            </NavLink>
            <NavLink to="missingrecord" className="link">
              <p>Contact Us</p>
            </NavLink>
          </div>
        </div>

        {/* Mobile Menu */}

        <div className="mobile-header">
          <Link to="/search"
            style={{ textDecoration: "none", color: "var(--color-white)" }}>
            <h1>KWiZDoM</h1>
          </Link>
          <div onClick={mobileMenuHandler}>
            <FontAwesomeIcon icon={faBars} />
          </div>

          {
            mobileMenu && <div className="mobile-box">
              <NavLink to="/" className="link">
                <p>Home</p>
              </NavLink>
              <NavLink to="/search" className="link">
                <p>Results</p>
              </NavLink>
              <NavLink to="/kwizdom" className="link">
                <p>About Kwizdom</p>
              </NavLink>
              <NavLink to="/grse" className="link">
                <p>About GRSE</p>
              </NavLink>
              <NavLink to="https://taazatv.com/" className="link" target="_blank">
                <p>TaazaTv</p>
              </NavLink>
              <NavLink to="/missingrecord" className="link">
                <p>Contact Us</p>
              </NavLink>
            </div>
          }

        </div>
      </main>
    </>
  );
};

export default Header;
